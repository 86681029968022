export const FirebaseConfig = {
	"projectId": "motorait",
	"appId": "1:256361378451:web:8985d40cedfcfe51569366",
	"databaseURL": "https://motorait-default-rtdb.firebaseio.com",
	"storageBucket": "motorait.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyBypbfVJfUk682lpZiVdsBbXZMtIbueNVo",
	"authDomain": "motorait.firebaseapp.com",
	"messagingSenderId": "256361378451",
	"measurementId": "G-XKD810YWYJ"
};