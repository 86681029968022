export const colors = {
    LandingPage_Front : '#F1F2F1',
    LandingPage_Background: '#E9E9E1',
    LandingPage_Description: '#373737',
    Button_Primary: '#EB5E57',
    Button_Secondary: '#d3131f', 
    Header_Background: "#FFFFFF",
    Header_Content: '#000000',
    AppMenu_Header: '#fff',
    ResponsiveDrawer_Header: '#d3131f',
}